<template>
  <div id="nav">
    <v-toolbar class="homepage-toolbar" dark>
      <v-app-bar-nav-icon
        class="d-lg-none"
        @click="drawer = true"
        
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link :to="{ name: 'home' }">
          <v-img :src="require('@/assets/logo.png')" width="200"></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <transition name="fade">
        <v-toolbar-items class="d-none d-lg-block">
          <v-btn
            v-for="(item, i) in menuItems"
            :key="i"
            :to="{ name: item.link }"
            >{{ item.text }}</v-btn
          >
        </v-toolbar-items>
      </transition>
    </v-toolbar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      dark
      
    >
      <v-list nav dense rounded>
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="i"
          link
          :to="item.link"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "TheNavbar",
  props: ["campusId"],
  data() {
    return {
      drawer: false,
      menuItems: [
        { text: "Planes de Estudio", link: "planes" },
        { text: "Solicitud", link: "solicitud" },
        { text: "Coordinadores", link: "coordinadores" },
        { text: "Preguntas frecuentes", link: "preguntas-frecuentes" },
        { text: "Primer semestre", link: "primer-semestre" },
      ],
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
